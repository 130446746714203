import React from 'react';
import GalleryItem from './GalleryItem';
// import GalleryItem2 from './GalleryItem2';
import images from "../data.json";
import "./GalleryFeed.css";

class GalleryFeed extends React.Component {
	constructor(props) {
		super(props);
		this.refGalWrapper = React.createRef();
		this.refGalItem = React.createRef();
		this.state = {
			imageList: images.items,
			grid: document.getElementsByClassName("grid")[0],
			// imgContWidth: Math.round((this.refGalWrapper.clientWidth - 80) / 3)
		};
    }

	render() {
		return (
			<>
				<div className='heading'>
					<h5>Responzivní Gallery Feed s použitím CSS gridu, s otevřením obrázku v lightboxu.</h5>
				</div>

				<div className={`b-gal b-gal-feed b-gal-grid b grid-${this.props.gridCount} pb-3`}>
				{/* <div className={`b-gal b-gal-feed b-gal-grid b grid-${this.props.data.gridCount}`}> */}
					<div className="b-gal-w pswp-gallery" id="feed-gallery" ref={this.refGalWrapper} >
						{this.state.imageList.map((image, id) =>
							<GalleryItem
							// <GalleryItem2
								key={id}
								galleryID="feed-gallery"
								ref={this.refGalItem}
								type = {image.type}
								small = {image.small}
								medium = {image.medium}
								large = {image.large}
								alt = {image.alt}
								width ={image.width}
								height = {image.height}
								sizes = {image.sizes}
								image={image}
								imgContWidth={this.props.imgContWidth}
								// galWrapper={this.refGalWrapper}
								// galWrapper={this.state.galWrapperState}
							/>
						)}
					</div>
				</div>
			</>
		)
	}
}

export default GalleryFeed;