import React from 'react';
import Picture from './Picture';

import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';
class GalleryItem extends React.Component {

	constructor(props) {
		super(props);

        this.refItem = React.createRef();
		this.refItemLink = React.createRef();
		this.refImgWrapper = React.createRef();
		this.refItemTitle = React.createRef();
		this.refPicture = React.createRef();

		this.state = {
            gridRowEnd: '',
			itemHeight: '',
			// imgContWidth: this.props.imgContWidth,
			// imgContWidth: this.refItemLink.current.clientWidth,
			// imgContWidth: this.refItemLink.current.getBoundingClientRect().width,
			// titleHeight: parseInt(document.querySelector(".b-gal-t").getBoundingClientRect().height, 10)
		};
    }

    componentDidMount() {
		// if (this.refItem.current) {
		// 	this.setState({imgContWidth: this.refItem.current.getBoundingClientRect().width})
		// };
		// this.setState({titleHeight: this.refItemTitle.current.getBoundingClientRect().height});
		this.resizeItemGalleryFeed();
        window.addEventListener('resize', this.resizeItemGalleryFeed);
		// const selectGrid = document.getElementById("select-grid");
		// selectGrid.addEventListener("onchange", console.log("CHANGE GRID"));
        // window.addEventListener('resize', this.setState({imgContWidth: this.refItemLink.current.getBoundingClientRect().width}));

		this.setLightbox();

		// this.resizeHeightRowHorizontal();
        // window.addEventListener('resize', this.resizeHeightRowHorizontal);
	}

	componentDidUpdate(prevState) {
		// const selectGrid = document.getElementById("select-grid");
		// selectGrid.addEventListener("onchange", this.resizeItemGalleryFeed);
		// if (this.state.imgContWidth !== prevState.imgContWidth) {
		// 	this.resizeItemGalleryFeed();
		// }
		// if (this.state.imgContWidth !== prevState.imgContWidth) {
		// this.setState({imgContWidth: this.refItemLink.current.getBoundingClientRect().width});
		// }
		// console.log(this.refItemLink.current.getBoundingClientRect().width);
		// console.log(this.state.imgContWidth);
	}

	componentWillUnmount() {
		this.resizeItemGalleryFeed();
		window.removeEventListener('resize', this.resizeItemGalleryFeed);
		// window.removeEventListener('resize', this.resizeHeightRowHorizontal);
	}

    // Funkce Gallery Feed na resize obrázků -------------------------------------------------------
	resizeItemGalleryFeed = () => {
		const gridItem = this.refItem.current.parentNode;
		const rowHeightItem = parseInt(window.getComputedStyle(gridItem).getPropertyValue('grid-auto-rows'), 10);
		const rowGapItem = parseInt(window.getComputedStyle(gridItem).getPropertyValue('grid-row-gap'),10);

		const imgOrigWidth = this.props.width;
		const imgOrigHeight = this.props.height;
		const imgContWidth = this.refItemLink.current.getBoundingClientRect().width;
		// const imgContWidth = this.refItemLink.current.clientWidth;
		// const widthALink = document.querySelector(".b-gal-a").getBoundingClientRect().width;
		// const heightTitle = parseInt(document.querySelector(".b-gal-t").getBoundingClientRect().height, 10);
		// const imgContWidth = this.state.imgContWidth !== 0 ? this.state.imgContWidth : this.refItemLink.current.getBoundingClientRect().width;

		// let imgRealHeight = 0;
		// if (this.props.imgContWidth != 0) {
		// 	imgRealHeight = imgOrigHeight * (this.props.imgContWidth / imgOrigWidth);
		// } else {
		// 	imgRealHeight = imgOrigHeight * (imgContWidth / imgOrigWidth);
		// }

		let imgRealHeight = imgOrigHeight * (imgContWidth / imgOrigWidth);

		if (this.props.alt !== "") {
			// imgRealHeight += parseInt(this.refItemTitle.current.getBoundingClientRect().height, 10);
			// this.state.titleHeight !== 0 ? 
			// imgRealHeight += parseInt(this.state.titleHeight, 10) :
			imgRealHeight += parseInt(this.refItemTitle.current.getBoundingClientRect().height, 10);
			// imgRealHeight += this.state.titleHeight;
		}

		const rowSpanItem = Math.ceil((imgRealHeight + rowGapItem) / (rowHeightItem + rowGapItem));
		this.setState({ gridRowEnd: `span ${rowSpanItem}` });
	}

    // Funkce Gallery Horizontal na připočtení výšky titlu k obrázku pokud je pod obrázkem ---------
	// resizeHeightRowHorizontal = () => {
	// 	const rowHeight = parseInt(this.refPicture.current.refImg.current.getBoundingClientRect().height, 10);
	// 	const titleRealHeight = parseInt(this.refItemTitle.current.getBoundingClientRect().height, 10);
	// 	const itemResizeHeight = rowHeight + titleRealHeight;
	// 	this.setState({ itemHeight: itemResizeHeight });
	// }

	setLightbox = () => {
		let lightbox = new PhotoSwipeLightbox({
			gallery: '#' + this.props.galleryID,
			children: 'a',
			pswpModule: () => import('photoswipe'),
			loop: false
		});
		lightbox.init();

		return () => {
			lightbox.destroy();
			lightbox = null;
		};
	};

	// Funkce na vyrenderování titlu obrázku jestliže obrázek nějaký title má ----------------------
	isTitleImg = () => {
		if (this.props.alt) {
			return (
				<div className="b-gal-t title-position"
						ref={this.refItemTitle}
				>
					<span>
						<span>{this.props.alt}</span>
					</span>
				</div>
			);
		}
	}

	render() {
		// console.log(this.state.imgContWidth);
		// this.eventHandleSelect();
		// console.log(this.refItem.current ? this.refItem.current.getBoundingClientRect().width : null, "GALLERY FEED ITEM");

		return (
			<div className={`b-gal-item`}
				key={this.props.galleryID + '-' + this.props.index}
				ref={this.refItem}
				style={{
					gridRowEnd: this.state.gridRowEnd,
					height: this.state.itemHeight,
				}}
			>
				<a
					className="b-gal-a"
					href={this.props.large}
					// width={this.props.width}
					// height={this.props.height}
					ref={this.refItemLink}

					data-pswp-width={this.props.width}
          			data-pswp-height={this.props.height}
					key={this.props.galleryID + '-' + this.props.index}
					target="_blank"
					rel="noreferrer"
				>
					<div className="b-gal-img"
					     ref={this.refImgWrapper}
					>
						<Picture
							ref={this.refPicture}
							type = {this.props.type}
							small = {this.props.small}
							medium = {this.props.medium}
							large = {this.props.large}
							alt = {this.props.alt}
							width ={this.props.width}
							height = {this.props.height}
							sizes = {this.props.sizes}
						/>
					</div>
				</a>
				{this.isTitleImg()}
			</div>
		);
	}
}

export default GalleryItem;