import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

const ProductDetailGallery = ({ pDetailData }) => {
    
    const [valUrlImg, setValUrlImg] = useState(pDetailData.thumbnail)
    // const [valImgFile, setValImgFile] = useState()
    
    const urlImgPlaceholder = "/img/img_placeholder.jpg"

    return (
        <div className="gallery">
            <div className="img-main">
                <img 
                    src={valUrlImg ? valUrlImg : "OBRÁZEK PRODUKTU NENÍ"}
                    // onLoad={() => console.log('Obrázek produktu na detailu produktu se načetl')} 
                    onError={() => setValUrlImg(urlImgPlaceholder)}
                    alt={pDetailData.name}
                />
            </div>

            {/* <ul className="thumbs"> */}
                <Swiper
                    spaceBetween={20}
                    slidesPerView={4}
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => console.log(swiper)}
                >
                    {pDetailData.images.map((Val, index) => {
                        // setValImgFile(Val.file);
                        // console.log(Val);
                        return (
                            <SwiperSlide key={index}>
                                {/* <li> */}
                                    {/* <a className="img" style={ {backgroundImage: `url(${Val.file})`} }></a> */}
                                    <a className="img" 
                                        onClick={() => setValUrlImg(Val)}
                                    >
                                        <img 
                                            src={Val ? Val : "OBRÁZEK PRODUKTU NENÍ"}
                                            // onLoad={() => console.log('Obrázek produktu na detailu produktu se načetl')} 
                                            // onError={() => setValImgFile(urlImgPlaceholder)}
                                            alt=""
                                        />
                                    </a>
                                {/* </li> */}
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            {/* </ul> */}
        </div>
    )
}

export default ProductDetailGallery;