import React, { useState } from "react";
import imgPlaceholder from "./img/img_placeholder.jpg";

const ProductBox = ({ item, onClickHandler, handleProductItem }) => {
    const [isIncart, setIsInCart] = useState(false);
    const [valUrlImg, setValUrlImg] = useState(item.thumbnail);
    const urlImgPlaceholder = imgPlaceholder;
  
    // console.log(item);
    return (
      <div className="product productItem" key={item.id}>
        {/* <a className="img openDetail" onClick={ () => {history.pushState(null, "", Val.slug); handleProductItem(Val.id, Val.name, Val.slug)} }> */}
        <a className="img openDetail" onClick={ () => handleProductItem(item.id) }>
        {/* <a className="img openDetail"> */}
          {/* <img src={item.thumbnail} alt={item.title} className="" /> */}
          <img
            // src={Val.thumbnail} 
            alt={item.title}
            className=""
            src={valUrlImg}
            // onLoad={() => console.log('Obrázek produktu se nahrál')}
            // onError={() => console.log('Obrázek produktu není')}
            onError={() => setValUrlImg(urlImgPlaceholder)}
            loading="lazy"
          />
        </a>
  
        <div className="desc">
          <a className="openDetail"
          // href={`/product/${Val.slug}`}
          onClick={() => handleProductItem(item.id)}
          >
            {item.title}
          </a>
          <div className="boxPrice">
            <span className="amount">{item.price} $</span>
            <div className="in-cart">{isIncart && "In cart"}</div>
            <button onClick={() => {
              setIsInCart(true);
              onClickHandler(item.id)
            }}>Add to cart</button>
          </div>
        </div>
      </div>
    );
  }

  export default ProductBox;