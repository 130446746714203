const SidebarFilterCheckbox = ({ fDataItem, handleChange }) => {
    // destructuring props

    return (
        <>
            {fDataItem.filters.map((Val) => {
                return (
                <li className="checkbox" key={Val.id}>
                    <input type="checkbox" id={`check-${Val.id}`} name={fDataItem.section_key} value={Val.id} title={Val.name} className="filter-on-change" onChange={handleChange}></input>
                    <label className="checkmark" htmlFor={`check-${Val.id}`}>
                        <span className={Val.icon !== null ? Val.icon : "circle"}></span>
                        <span className="name">{Val.name}</span>
                    </label>
                </li>
                );
            })}
        </>
    );
};

export default SidebarFilterCheckbox;