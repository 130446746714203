const Sorting = ({ sortingActive, handleSorting }) => {

	const sortingOptions = {
		items: [
			{
				text: "Default sorting",
				href: "id"
			},
			{
				text: "Best price",
				href: "price"
			},
			{
				text: "Best rating",
				href: "rating"
			},
			// {
			// 	text: "Nejnovější",
			// 	href: "created"
			// }
		]
	}
	// console.log(sortingOptions.items);
	
	return (
		<div className="sorting-row">
			<ul className="submenu ajax-sort">
				{sortingOptions.items.map((Val, index) => {
					return (
						<li key={index} 
							className={sortingActive === index ? "active" : ""}
						>
							<a 
								// href={`/product/?sort=${Val.href}`} 
								onClick={() => handleSorting(index, Val.href)}
								>{Val.text}</a>
						</li>
					)
				})}
			</ul>
		</div>
	)
}

export default Sorting;