import ProductDetailGallery from "./ProductDetailGallery.jsx";
import GallerySwiperMobile from "./GallerySwiperMobile.jsx";
import ProductDetailLabels from "./ProductDetailLabels.jsx";
// import SimilarProducts from "./SimilarProducts.jsx";
import "./ProductDetail.css";

const ProductDetail = ({ pDetailData, active, handlerClose, handleCloseAndRewrite, handleParams, handlerAddToCart, handleProductItem, handlerSimilarProduct, onClickHandler }) => {

    return (
        <div className={`layer full ${active}`} id="detail">
            <div className="overlayer closelayer" onClick={handlerClose}></div>
            {/* <div className="overlayer closelayer" onClick={handleCloseAndRewrite}></div> */}
            <div className="box">
                <div className="over">
                    <div className="bg">
                        <div className="detail">

                            <div className="col55">
                                <div className="inner desktop">
                                    <ProductDetailGallery pDetailData={pDetailData} />
                                </div>
                                <GallerySwiperMobile pDetailData={pDetailData} />
                            </div>

                            <div className="col45">
                                <ProductDetailLabels
                                    pDetailData={pDetailData}
                                    handlerAddToCart={handlerAddToCart}
                                    handlerClose={handlerClose}
                                    handleParams={handleParams}
                                    onClickHandler={onClickHandler}
                                />
                            </div>
                            <div className="clear"></div>
                            <div className="inner description">
                                <h3>Description</h3>
                                <p>{pDetailData.description}</p>
                            </div>
                        </div>

                        {/* <SimilarProducts pDetailData={pDetailData} /> */}
                        {/* <SimilarProducts 
                                pDetailDataRelated={pDetailData.related}
                                handleProductItem={handleProductItem}
                                handlerClose={handlerClose}
                                handlerSimilarProduct={handlerSimilarProduct}
                            /> */}

                    </div>
                    <a className="ic_close closelayer"
                        onClick={handlerClose}
                    // onClick={handleCloseAndRewrite}
                    // onClick={handleProductItem(0)}
                    // href="/product/"
                    >Zavřít</a>
                </div>
            </div>

        </div>
    )
}

export default ProductDetail;