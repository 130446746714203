import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter, Routes, Route } from "react-router-dom";
import './index.css';
import App from './App';
import Homepage from "./Homepage";
import Pokeapi from './pokeapi-itnetwork/AppPokeapi';
// import GalleryMasonry from './gallery/GalleryMasonry';
// import GalleryBlock from './gallery/GalleryBlock';
import GalleryFeed from './gallery/GalleryFeed';
import GalleryClassic from './gallery/GalleryClassic';
import GalleryHorizontal from './gallery/GalleryHorizontal';
import Eshop from './eshop/AppEshop';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <HashRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="/" element={<Homepage />} />
          <Route path="eshop" element={<Eshop />} />
          {/* <Route path="gallery" element={<GalleryBlock />} /> */}
          <Route path="gallery-classic" element={<GalleryClassic />} />
          {/* <Route path="gallery-masonry" element={<GalleryMasonry />} /> */}
          <Route path="gallery-feed" element={<GalleryFeed />} />
          <Route path="gallery-horizontal" element={<GalleryHorizontal />} />
          <Route path="pokeapi" element={<Pokeapi />} />
        </Route>
      </Routes>
    </HashRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
