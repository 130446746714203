import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from "react-router-dom";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/a11y';
import 'swiper/css/autoplay';

import images from "./data.json";
import Img1 from "./img/animovane-bannery.jpg";
import Img2 from "./img/horni-bojanovice.jpg";
import Img3 from "./img/ivk.jpg";
import Img4 from "./img/kchmpp.jpg";
import Img5 from "./img/motorservis.jpg";
import Img6 from "./img/palavaart.jpg";
import Img7 from "./img/rytirivina.jpg";
import Img8 from "./img/sklipek-u-kozliku.jpg";
import Img9 from "./img/vinaripavlov.jpg";
import Img10 from "./img/vinport.jpg";
import Img11 from "./img/ynna.jpg";
import Img12 from "./img/zs-komenskeho.jpg";
// import Picture from './gallery/Picture';
// import GalleryBlock from './gallery/GalleryBlock';

function Homepage() {

    const dataImages = images.items;
    const titles = [
        "Eshop",
        "Gallery Classic",
        "Gallery Feed",
        "Gallery Horizontal",
        "Pokeapi"
    ]

    const subtitles = [
        "Výpis produktů a detail produktu načítající data z API s funkcí přidávání do košíku, filtrováním a řazením produktů.",
        "Responzivní galerie načítající data z API s funkcí načtení dalších položek.",
        'Responzivní galerie "Masonry" s použitím CSS gridu.',
        "Responzivní galerie s použitím CSS Flexbox.",
        "React aplikace načítající data z API s fukncí stránkování."
    ]

    const headerLink = [
        "/eshop",
        "/gallery-classic",
        "/gallery-feed",
        "/gallery-horizontal",
        "/pokeapi"
    ]

    const referenceImages = [
        { img: Img9,
            link: "https://www.vinaripavlov.cz" },
        { img: Img11,
            link: "https://www.ynna.cz" },
        { img: Img12,
            link: "https://www.zshuskom.cz" },
        { img: Img2,
            link: "https://www.hornibojanovice.cz" },
        { img: Img3,
            link: "https://www.ivk.cz" },
        { img: Img4,
            link: "https://www.kchmpp.cz" },
        { img: Img5,
            link: "https://www.motorservis.cz" },
        { img: Img7,
            link: "http://www.rytirivina.cz" },
        { img: Img10,
            link: "https://www.vinport.cz" },
        { img: Img8,
            link: "https://www.sklipekukozliku.cz" },
        { img: Img6,
            link: "https://www.palavaart.cz" },
        { img: Img1,
            link: "https://www.animovanebannery.cz" }
    ]

    console.log(dataImages);
    return (
        <>
            <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                spaceBetween={0}
                slidesPerView={1}
                navigation={true}
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                }}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
                >
                {dataImages.slice(5).map((image) => {

                    return (
                        <SwiperSlide key={image.id}>
                            <img
                                type = {image.type}
                                src = {image.large}
                                alt = {image.alt}
                                width ={image.width}
                                height = {image.height}
                            />
                            <div className='titles'>
                                <h2>{image.id === 6 ? titles[0] : image.id === 7 ? titles[1] : image.id === 8 ? titles[2] : image.id === 9 ? titles[3] : image.id === 10 ? titles[4] : null}</h2>
                                <p>{image.id === 6 ? subtitles[0] : image.id === 7 ? subtitles[1] : image.id === 8 ? subtitles[2] : image.id === 9 ? subtitles[3] : image.id === 10 ? subtitles[4] : null}</p>
                                <Link to={image.id === 6 ? headerLink[0] : image.id === 7 ? headerLink[1] : image.id === 8 ? headerLink[2] : image.id === 9 ? headerLink[3] : image.id === 10 ? headerLink[4] : null}>View</Link>
                            </div>
                        </SwiperSlide>
                    )
                })}

                {/* <SwiperSlide>Slide 1</SwiperSlide>
                <SwiperSlide>Slide 2</SwiperSlide>
                <SwiperSlide>Slide 3</SwiperSlide>
                <SwiperSlide>Slide 4</SwiperSlide> */}
            </Swiper>
            <div className="container max-w-0">
                <h4 className="pt-2">Reference</h4>
                <p>Ukázka tvorby responzivních webových stránek, jejich grafického zpracování, řešení UI a UX a následného nakódování.</p>
                <div className='grid b-gal pb-3'>
                    {referenceImages.map((img, index) => {
                        return (
                            <Link to={img.link} target="_blank" className='pb-2'>
                                <img key={index} src={img.img} alt='' width="400" height="200"/>
                                {img.link.replace("https://", "")}
                            </Link>
                        )
                    })}
                </div>
                {/* <GalleryBlock /> */}
            </div>
        </>
    );
}

export default Homepage;