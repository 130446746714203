import React from 'react';
import GalleryItem from './GalleryItem';
// import GalleryItem2 from './GalleryItem2';
// import images from "../data.json";
import "./GalleryClassic.css";

const id = "wnd_PhotoGalleryBlock_20821647";

class GalleryClassic extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			// imageList: images.items,
			photoList: [],
			isLoaded: true,
			countPhotos: 20
		};
		this.handleEvent = this.handleEvent.bind(this);
	}

	componentDidMount() {
		fetch('https://jsonplaceholder.typicode.com/photos')
		.then((response) => response.json())
		.then((json) => this.setState({
			photoList: json,
			isLoaded: false
		}) );
	}

	handleEvent = () => {
		this.setState({
			countPhotos: this.state.countPhotos + 20
		})
	}

	render() {
		console.log(!this.state.isLoaded ? "photoList: " + this.state.photoList.length + " photos" : null);

		return (
			<>
				<div className='heading'>
					<h5>Responzivní Gallery Classic s načítáním dat z API s možností načtení dalších obrázků a otevřením obrázku v lightboxu.</h5>
				</div>

				<div className={`b-gal b-gal-classic b-gal-grid b grid-${this.props.gridCount} pb-3`} id={id}>

					<div className="b-gal-w grid pswp-gallery" id="classic-gallery">
						{!this.state.isLoaded ? this.state.photoList.slice(0, [this.state.countPhotos]).map((photo, id) =>
							<GalleryItem
							// <GalleryItem2
								key={id}
								galleryID="classic-gallery"
								type={"image/png"}
								small={photo.thumbnailUrl}
								medium={photo.url}
								large={photo.url}
								alt={photo.title}
								width={600}
								height={600}
								sizes={"(min-width: 768px) calc(100vw * 0.3), (min-width: 320px) calc(100vw * 0.5), 100vw"}
							/>
						) : null}
					</div>

					{/* <div className="b-gal-w grid">
						
						{this.state.imageList.map((image, index) =>
							<GalleryItem key={index}
							type = {image.type}
							small = {image.small}
							medium = {image.medium}
							large = {image.large}
							alt = {image.alt}
							width ={image.width}
							height = {image.height}
							sizes = {image.sizes}
							/>
						)}
					</div> */}
					
					<div className='container pb-3'>
							<button onClick={this.handleEvent}>Load more</button>
					</div>
				</div>
			</>
		)
	}
}

export default GalleryClassic;

// ReactDOM.render(<Gallery />, document.querySelector('.ez-c'));
