import React from 'react';
import GalleryItem from './GalleryItem';
// import GalleryItem2 from './GalleryItem2';
import images from "../data.json";
import "./GalleryHorizontal.css";

class GalleryHorizontal extends React.Component {

    constructor(props) {
		super(props);
		this.state = {
			imageList: images.items
		};
	}

	render() {
		return (
			<>
				<div className='heading'>
					<h5>Responzivní Gallery Horizontal s použitím CSS flexboxu, s otevřením obrázku v lightboxu.</h5>
				</div>

				<div className="b-gal b-gal-horizontal b pb-3">
					<div className="b-gal-w pswp-gallery" id="horizontal-gallery">
						{this.state.imageList.map((image, index) =>
							<GalleryItem
							// <GalleryItem2
								key={index}
								galleryID="horizontal-gallery"
								type={image.type}
								small={image.small}
								medium={image.medium}
								large={image.large}
								alt={image.alt}
								width={image.width}
								height={image.height}
								sizes={image.sizes}
							/>
						)}
					</div>
					<div className="wnd-empty" />
				</div>
			</>
		)
	}
}

export default GalleryHorizontal;
