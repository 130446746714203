import React, { useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/a11y';

const GallerySwiperMobile = ({ pDetailData }) => {
    // const [valImgFile, setValImgFile] = useState()
    // const urlImgPlaceholder = "/img/img_placeholder.jpg"

    return (
        <div className="swiper mobile">
            <Swiper
                modules={[Navigation, Pagination, A11y]}
                spaceBetween={0}
                slidesPerView={1}
                navigation={true}
                pagination={{ clickable: true }}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
            >
                {pDetailData.images.map((Val, index) => {

                    return (
                        <SwiperSlide key={index}>
                            <a className="img">
                                <img
                                    src={Val ? Val : "OBRÁZEK PRODUKTU NENÍ"}
                                    // onLoad={() => console.log('Obrázek produktu na detailu produktu se načetl')}
                                    // onError={() => setValImgFile(urlImgPlaceholder)}
                                    alt=""
                                />
                            </a>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
    </div>
    )
}

export default GallerySwiperMobile;