import { useState } from "react";
import SidebarFilterCheckbox from "./SidebarFilterCheckbox.jsx";
import "./Sidebar.css";

const Sidebar = ({ fData, handleChange }) => {
    // destructuring props

    const [addClass, setAddClass] = useState ({
        openTrigger: " close",
        elmOpen: " d-block",
        elmClose: " d-none"

    })
    const handlerOpen = () => {
        setAddClass({openTrigger: " open", elmOpen: " d-none", elmClose: " d-block"});
    }
    const handlerClose = () => {
        setAddClass({openTrigger: " close", elmOpen: " d-block", elmClose: " d-none"});
    }

    // console.log("SIDEBAR SE VYRENDEROVAL");

    return (
        <aside className={"sidebar" + addClass.openTrigger}>
            <div className="text-left">
                <div className="filter">
                    <button className={"ic_arrow" + addClass.elmOpen} onClick={handlerOpen}>Otevřít</button>
                    <button className={"ic_close" + addClass.elmClose} onClick={handlerClose}>Zavřít</button>
                    <ul className="category-filter">

                        {/* <h3>{fData[0].section_name}</h3>

                        <SidebarFilterCheckbox
                            fDataItem={fData[0]}
                            handleChange={handleChange}
                        />

                        <h3 className="mt-20">{fData[1].section_name}</h3>

                        <SidebarFilterCheckbox
                            fDataItem={fData[1]}
                            handleChange={handleChange}
                        />

                        <h3 className="mt-20">{fData[2].section_name}</h3>

                        <SidebarFilterCheckbox
                            fDataItem={fData[2]}
                            handleChange={handleChange}
                        /> */}

                        <h3 className="mt-20">{fData[3].section_name}</h3>

                        <SidebarFilterCheckbox
                            fDataItem={fData[3]}
                            handleChange={handleChange}
                        />

                    </ul>
                </div>
            </div>
        </aside>
    )
}

export default Sidebar;