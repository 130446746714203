import React, { useState } from 'react';
import { Outlet, NavLink, Link } from "react-router-dom";
import { slide as Menu } from 'react-burger-menu';
import './App.css';
import './milligram.css';
import './HamburgerMenu.css';
import MenuIcon from "./eshop/img/ic_menu.png";
import CrossIcon from "./eshop/img_svg/zavrit-light.svg";
import { FaHome } from "react-icons/fa";

function App() {
  const [classActive, setClassActive] = useState("active");
  const [idState, setIdState] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);

  const handleStateChange = (state) => {
    setMenuOpen(state.menuOpen)  
  }

  const closeMenu = (event) => {
    setClassActive("active")
    setIdState(event.target.id)
    setMenuOpen(false)
  }

  console.log(classActive, "USESTATE");
  return (
    <div className="App" id="outer-container">

      <Menu
        right
        isOpen={menuOpen}
        onStateChange={(state) => handleStateChange(state)}
        disableAutoFocus
        customBurgerIcon={ <img src={MenuIcon} /> }
        customCrossIcon={ <img src={CrossIcon} /> }
      >
        <NavLink id="home" className={idState === "home" ? "menu-item " + classActive : "menu-item"} 
          onClick={(event) => closeMenu(event)} to="/">Home</NavLink>
        <NavLink id="eshop" className={idState === "eshop" ? "menu-item " + classActive : "menu-item"} 
          onClick={(event) => closeMenu(event)} to="/eshop">Eshop</NavLink>
        <NavLink id="gal-classic" className={idState === "gal-classic" ? "menu-item " + classActive : "menu-item"} 
          onClick={(event) => closeMenu(event)} to="/gallery-classic">Gallery Classic</NavLink>
        <NavLink id="gal-feed" className={idState === "gal-feed" ? "menu-item " + classActive : "menu-item"} 
          onClick={(event) => closeMenu(event)} to="/gallery-feed">Gallery Feed</NavLink>
        <NavLink id="gal-hrzntl" className={idState === "gal-hrzntl" ? "menu-item " + classActive : "menu-item"} 
          onClick={(event) => closeMenu(event)} to="/gallery-horizontal">Gallery Horizontal</NavLink>
        <NavLink id="pokeapi" className={idState === "pokeapi" ? "menu-item " + classActive : "menu-item"} 
          onClick={(event) => closeMenu(event)} to="/pokeapi">Pokeapi</NavLink>
      </Menu>

      <div id="page-wrap">
        <header>
          <Link id="logo" onClick={() => setClassActive("")} to="/"><h1>React Web & Apps</h1></Link>
          {/* <h1>React Web & Apps</h1> */}
          <nav className='nav-desktop'>
            <NavLink id="nav-home" to="/"><FaHome/>Home</NavLink>
            <NavLink to="/eshop">Eshop</NavLink>
            {/* <NavLink className={({ isActive }) => isActive ? "active" : null} to="/gallery">Gallery</NavLink> */}
            <NavLink to="/gallery-classic">Gallery Classic</NavLink>
            {/* <NavLink className={({ isActive }) => isActive ? "active" : null} to="/gallery-masonry">Gallery Masonry</NavLink> */}
            <NavLink to="/gallery-feed">Gallery Feed</NavLink>
            <NavLink to="/gallery-horizontal">Gallery Horizontal</NavLink>
            <NavLink to="/pokeapi">Pokeapi</NavLink>
          </nav>
        </header>
        <section>
          <Outlet />
        </section>
        <footer>
          <p>
            Created with React © 2023.
          </p>
          <a className="App-link" href="https://www.palavaart.cz">Palavaart.cz</a>
          <span> | </span>
          <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn React
          </a>
        </footer>
      </div>
    </div>
  );
}

export default App;
