import React, { useState, useEffect } from "react";

// Dořešit načítání odkazu do href na kategorii z filtrů
// Ve vzorových datech chybí Velikost, Materiál, Silueta, Dašlí vlastnosti, Tagy.

const ProductDetailLabels = ({ pDetailData, handlerAddToCart, handlerClose, handleParams, onClickHandler }) => {
    // const [itemCount, setItemCount] = useState(1);ˇ
    const [isIncart, setIsInCart] = useState(false);
    const limit = pDetailData.stock;
    const [tooltipVisible, setTooltipVisible] = useState({
        tooltipClass: "",
        tooltipSpan: false,
        tooltipAddToCartClass: "",
        tooltipAddToCartSpan: false
    });

    // const decrease = () => {
    //     setItemCount(Math.max(itemCount - 1, 1));
    // };
    // const increase = () => {
    //     itemCount < limit ? setItemCount(itemCount + 1) : null;
    //     itemCount == limit ? setTooltipVisible({tooltipClass: " tooltip-visible", tooltipSpan: true}) : null;
    //     itemCount == limit ? getTimeoutFunction() : null;
    // };

    // const showTooltip = () => {
    //     setTooltipVisible({tooltipClass: " tooltip-visible", tooltipAddToCartSpan: true})
    // };

    // const getTimeoutFunction = () => {
    //     const timer = setTimeout(() => {
    //         setTooltipVisible({tooltipClass: "", tooltipSpan: 0, tooltipAddToCartSpan: false});
    //       }, 5000);
    //       return () => clearTimeout(timer);
    // }
    
    return (
        <div className="inner">
            {pDetailData.category ?
                <ul className="breadcrumbs">
                    <li>
                        <a name={pDetailData.category} 
                            onClick={handleParams}
                        >
                            {pDetailData.category}
                        </a>
                    </li>
                </ul>
                : null
            }

            <h2>{pDetailData.title}</h2>

            {pDetailData.brand ? 
                <p>Brand: {pDetailData.brand}</p>
            : null
            }

            <div className="clear"></div>

            <div className="amount">
                <p>Price: <span className="price">{pDetailData.price} $</span>
                    {!pDetailData.discountPercentage == 0 || !pDetailData.discountPercentage == null ? <span className="old-price">-{pDetailData.discountPercentage} %</span> : null}
                </p>
            </div>

            <div className="clear"></div>

            {limit <= 0 ? 
            <div className="add-to-cart-block">
                {/* <div className="count large">
                    <button className="btn-count disabled" type="button" disabled>
                        <span className="minus disabled"></span>
                    </button>
                    <input type="text" value="0" disabled />
                    <button className="btn-count disabled" type="button" disabled>
                        <span className="plus disabled"></span>
                    </button>
                </div> */}
                <button className="ic_cart desktop disabled" disabled>Není skladem</button>
                <button className="ic_cart inline mobile disabled" disabled>Není skladem</button>
            </div>
            :
            <div className={"add-to-cart-block" + tooltipVisible.tooltipClass}>
                {/* <div className="count large">
                    <button className="btn-count" type="button" 
                        // onClick={() => decrease()}
                    >
                        <span className="minus"></span>
                    </button>
                    <input type="text" value={itemCount} />
                    <button className="btn-count" type="button" 
                        // onClick={() => increase()}
                    >
                        <span className="plus"></span>
                    </button>
                </div> */}
                {tooltipVisible.tooltipSpan === true ? <span class="tooltiptext">Více kusů není skladem</span> : null}

                {tooltipVisible.tooltipAddToCartSpan === true ? <span class="tooltiptext tooltip-cart">Produkt byl vložen do košíku</span> : null}
                <div className="in-cart">{isIncart && "In cart"}</div>
                <button className="ic_cart desktop" 
                    // onClick={() => {showTooltip(); getTimeoutFunction(); handlerAddToCart(pDetailData.id, itemCount)}}
                    onClick={() => {
                        setIsInCart(true);
                        onClickHandler(pDetailData.id)
                      }}
                >Add to cart</button>
                <button className="ic_cart inline mobile" 
                    // onClick={() => {showTooltip(); getTimeoutFunction(); handlerAddToCart(pDetailData.id, itemCount)}}
                    onClick={() => {
                        setIsInCart(true);
                        onClickHandler(pDetailData.id)
                      }}
                >Add to cart</button>
            </div>
            }

            <ul className="tags">
                {pDetailData.category ? 
                    <li>
                        <a name={pDetailData.category} 
                            // onClick={handleParams}
                        >{pDetailData.category}</a>
                    </li> 
                : null}
                {pDetailData.brand ? 
                    <li>
                        <a name={pDetailData.brand} 
                            // onClick={handleParams}
                        >{pDetailData.brand}</a>
                    </li> 
                : null}
            </ul>
        </div>
    )
}

export default ProductDetailLabels;