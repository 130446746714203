import React from 'react';
import { useState, useEffect } from "react";
// import Product from "./Product";
// import data from "./products.json";
import "./AppEshop.css";
import { FaBasketShopping } from "react-icons/fa6";

import FiltersData from "./filters-data.json";
// import ProductDetailData from "./product-data.json";
// import ProductsData from "./product-list-data.json";
import Sidebar from "./Sidebar.jsx";
import ProductList from './ProductList.jsx';
import ProductDetail from "./ProductDetail.jsx";
// import { toBasicLatin } from "./ToBasicLatinAndConvertToLowerCase.js";

function AppEshop() {

    // Produktové data ve výpisu produktů ------------------------------------------
    const [productsData, setProductsData] = useState({
        items: [],
        isLoaded: true,
        error: null,
        filteredItems: [],
        filteredItems2: [],
    });

    // GET request pro získání dat produktů ----------------------------------------
    const getProductData = () => {
        fetch('https://dummyjson.com/products?limit=100')
            .then(res => res.json())
            .then(json =>
                // console.log(json);
                setProductsData({
                    items: json,
                    isLoaded: false
                })
            )
            .catch((error) => {
                setProductsData({ error, isLoaded: false })
                console.log(error.message);
            });
    }

    // Počáteční hodnoty filtrů --------------------------------------------------
    const [category1, setCategory1] = useState({
        valueCategory1: [],
        isChecked: false,
    });
    const [category2, setCategory2] = useState({
        valueCategory2: [],
        isChecked: false
    });
    const [mergedCategory, setMergedCategory] = useState();

    // Event handlery pro checkboxy filtrování -------------------------------------
    const checkCategory = (event) => {
        const { title, checked } = event.target;
        // console.log(event.target);
        const { valueCategory1 } = category1;
        const { valueCategory2 } = category2;

        let item = productsData.items.products;

        if (checked) {
            if (title === "Smartphones") {
                let filteredCategory = item.filter((item) => {
                    return item.category === "smartphones"
                })
                setCategory1({ valueCategory1: filteredCategory, isChecked: true });
                // console.log(filteredCategory, "Smartphones is checked");
            } else if (title === "Laptops") {
                let filteredCategory = item.filter((item) => {
                    return item.category === "laptops"
                })
                setCategory2({ valueCategory2: filteredCategory, isChecked: true });
                // console.log(filteredCategory, "Laptops is checked");
            } else {
                console.log("NOTHINGS was checked");
            }
                    
        } else {
            if (title === "Smartphones") {
                setCategory1({ valueCategory1: valueCategory1.filter((item) => {return item.category !== "smartphones"}), isChecked: false });
                // console.log(valueCategory1, "Smartphones is NOT checked and Laptops???");
            } else if (title === "Laptops") {
                setCategory2({ valueCategory2: valueCategory2.filter((item) => {return item.category !== "laptops"}), isChecked: false });
                // console.log(valueCategory2, "Laptops is NOT checked and Smartphones???");
            } else {
                console.log("NOTHINGS was UNchecked");
            }
        }
        // console.log(item, "Produktové data");
    }

    // Event handlery odkazů na detailu produktu pro zobrazení vyfiltrovaných produktů -----------
    const handleParams = (event) => {
        // Destructuring
        const { name} = event.target;
        // const { newAllTitle } = allTitle;
        // console.log(event);

        const selectFilter = document.getElementById(`check-${name}`);
        const noSelectFilters = document.querySelectorAll(`.filter-on-change:not([id="check-${name}"])`);
        const convertNoSelectFilters = [...noSelectFilters];

        let item = productsData.items.products;

        if (name === "smartphones") {
            let filteredCategory = item.filter((item) => {
                return item.category === "smartphones"
            })
            setCategory1({ valueCategory1: filteredCategory, isChecked: true });
            setCategory2({ valueCategory2: [], isChecked: false });
            convertNoSelectFilters.map((val) => {val.checked = false});
            selectFilter.checked = true;
            // console.log(filteredCategory, "Smartphones is checked");
        } else if (name === "laptops") {
            let filteredCategory = item.filter((item) => {
                return item.category === "laptops"
            })
            setCategory2({ valueCategory2: filteredCategory, isChecked: true });
            setCategory1({ valueCategory1: [], isChecked: false });
            convertNoSelectFilters.map((val) => {val.checked = false});
            selectFilter.checked = true;
            // console.log(filteredCategory, "Laptops is checked");
        } else {
            console.log("NOTHINGS was checked");
        }
        handlerClose();
    }

    // Sorting produktů a jejich výchozí stav --------------------------------
	const [sortingActive, setSortingActive] = useState(0);
    const [sortHref, setSortHref] = useState("id");
    // const [closeDetailProduct, setCloseDetailProduct] = useState(false);

    const handleSorting = (index, href) => {
		setSortingActive(index);
        setSortHref(href);
        // postData();
        // rewriteUrlFilters();
        // history.pushState(null, "", `?sort=${href}`);
        // console.log(productsData.items.products.sort((a, b) => a.price - b.price));
        // console.log(mergedCategory.sort((a, b) => a.price - b.price));
        // console.log("INDEX: " + index + ", Řazení: " + href);
    }

    // Produktové data v detailu produktu ---------------------------------------
    const [productDetailData, setProductDetailData] = useState();

    // Event handler pro zobrazení a odstranění detailu produktu ----------------
    const [productItem, setproductItem] = useState({
        productID: 0,
        active: ""
    });
    
    const handleProductItem = (id) => {
		// console.log("ID PRODUKTU: " + id, "a SLUG PRODUKTU: " + slug);
        // const newUrl = `${pathlUrl}${slug}`;

        // GET request pro načtení dat detailu produktu --------------------------
        const fetchProduktDetailData = () => {
            fetch(`https://dummyjson.com/products/${id}`)
                .then((res) => res.json())
                .then((productDetailData) => {
                    // console.log(productDetailData);
                    setProductDetailData(productDetailData);
                    setproductItem({productID: productDetailData.id, active: "active"});

                    // history.pushState(null, "", productDetailData.data.slug);
					// if ( location.href.indexOf( productDetailData.data.slug ) == -1 ) {
                    // 	history.pushState(null, "", productDetailData.data.slug);
					// }
                    document.title = document.title + " | " + productDetailData.title;
                })
                .catch((error) => {
                    console.log(error.message);
                });
        }
        fetchProduktDetailData();
        document.body.classList.add('over-hidden');
	}

    const handlerClose = () => {
        setproductItem({productID: 0, active: ""});
        // setCloseDetailProduct(true);
        // history.replaceState(null, "", "/product/");
        // history.replaceState(null, "", `/product/?sort=${sortHref}`);
        // rewriteUrlFilters();
        document.title = "React App";
        document.body.classList.remove('over-hidden');
    }

    // Košík ------------------------------------------------------------------
    const [cart, setCart] = useState([])

    // Event handler přidání produktu do košíku -------------------------------
    const handler = function(product) {
        const newCart = [...cart];
        newCart.push(product);
        // console.log("Položky v košíku s ID: " + newCart);
        setCart(newCart)
    }

    useEffect(() => {
        getProductData();
    }, []);

    // Funkce sloučení vyfiltrovaných produktů -------------------------------------
    useEffect(() => {
        setMergedCategory(category1.valueCategory1.concat(category2.valueCategory2))
    }, [category1, category2]);
    
    return (
        <div className='wrapper pb-3'>
            <div className='heading'>
                <h5>Responzivní eshop s výpisem produktů a detailem produktu načítající data z API. S funkcí přidáváním do košíku, filtrováním a řazením produktů.</h5>
            </div>

            <div className='container row-cart'>
                <a href="./eshop#/eshop" className="cart" onClick={(e)=> e.preventDefault()}>
                    <p className='mb-0'>Cart</p>
                    <FaBasketShopping />
                    <span className={cart.length > 0 ? "add-item" : null}>{cart.length}</span> 
                </a>
            </div>
            <div className='container d-flex-row'>
                <Sidebar 
                    fData={FiltersData.data} 
                    // handleChange={checkCategory}
                    handleChange={checkCategory}
                />
                {!productsData.isLoaded ? (
                    <ProductList 
                        pData={mergedCategory.length !== 0 ? mergedCategory : productsData.items.products}
                        // pData={productsData.items.products}
                        sortingActive={sortingActive}
                        handleSorting={handleSorting}
                        sortHref={sortHref}
                        handleProductItem={handleProductItem}
                        productID={productItem.productID}
                        onClickHandler={handler}
                    />
                ) : (
                    <p>Načítání...</p>
                )}
            </div>
                {productItem.productID !== 0 
                    ? 
                    <ProductDetail
                        pDetailData={productDetailData}
                        active={productItem.active}
                        handlerClose={handlerClose}
                        // handleCloseAndRewrite={handleCloseAndRewrite}
                        handleParams={handleParams}
                        // handlerAddToCart={handlerAddToCart}
                        handleProductItem={handleProductItem}
                        // handlerSimilarProduct={handlerSimilarProduct}
                        onClickHandler={handler}
                    /> 
                    : null
                    // console.log(`--PRODUKT ID: ${productItem.productID}`)
                }
                {/* <div className="productList grid">
                    {data.products.map(item => <Product key={item.ean} product={item} onClickHandler={handler} />)}
                </div> */}
        </div>
    )
} 

export default AppEshop