import { useState } from "react";
import ProductBox from "./ProductBox.jsx";
// import ProductBox2 from "./ProductBox2.jsx";
import Sorting from "./Sorting.jsx";
import "./ProductList.css";

const ProductList = ({ pData, sortingActive, handleSorting, sortHref, productID, handleProductItem, onClickHandler }) => {

  const [countProducts, setCountProducts] = useState(10);

  const handleEventLoadMore = () => {
    setCountProducts(countProducts + 10)
  }

  return (
    <div className="archive">
      <Sorting
        sortingActive={sortingActive}
        handleSorting={handleSorting}
      />
      <div className="products">
        {sortHref === "price" ?
          pData.slice(0, [countProducts])
            .sort((a, b) => a.price - b.price)
            .map(item =>
              <ProductBox key={item.id}
                item={item}
                onClickHandler={onClickHandler}
                handleProductItem={handleProductItem}
              />)
          : sortHref === "rating" ?
            pData.slice(0, [countProducts])
              .sort((a, b) => a.rating - b.rating)
              .map(item =>
                <ProductBox key={item.id}
                  item={item}
                  onClickHandler={onClickHandler}
                  handleProductItem={handleProductItem}
                />)
            : pData.slice(0, [countProducts])
              .map(item =>
                <ProductBox key={item.id}
                  item={item}
                  onClickHandler={onClickHandler}
                  handleProductItem={handleProductItem}
                />)
        }
        {/* <ProductBox2 
          item={pData}
          // productID={productID}
          // handleProductItem={handleProductItem}
          onClickHandler={onClickHandler}
        /> */}
      </div>
      <div className='container mt-2'>
        <button onClick={handleEventLoadMore}>Load more</button>
      </div>
    </div>
  );
};

export default ProductList;
